import React from 'react';
import { Box, Typography, Button } from '@mui/material';
const ConfirmationStep = ({ confirmation, handleBack }) => {

  return (
    <Box>
        <Typography variant="h6" gutterBottom>Confirmation</Typography>
        {confirmation ? (
            <Typography variant="body1" paragraph>
                Your account has been created successfully!
                <br/><br/>
                We will now <a href="/">redirect you to the login page</a>.
            </Typography>
        ) : (
            <>
                <Typography variant="body1" color="error" paragraph>
                There was an error with your submission. Please try again.
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'flex-start', mt: 2 }}>
                    <Button variant="outlined" color="primary" onClick={handleBack}>
                    Back
                    </Button>
            </Box>
        </>
        )}
        {confirmation && <span style={{ visibility: 'hidden' }}><span
            style={{ visibility: 'hidden' }}>
            {setTimeout(() => { window.location.href = "/"; }, 1500)}
        </span></span>}
    </Box>
  );
};

export default ConfirmationStep;