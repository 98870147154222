import QuizIcon from "@mui/icons-material/Quiz";
import RobotIcon from "@mui/icons-material/SmartToy";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
//import StyleIcon from "@mui/icons-material/Style"; //*For Future FlashCard Feature*
import React, { useState } from "react";
import StartQuizModal from "../StartQuizModal";

export const StartSpeedDial = ({ onSpeedDialClick, onStartQuizClick, sx }) => {
  const [quizModalOpen, setQuizModalOpen] = useState(false);

  const [open, setOpen] = useState(false);

  const handleOpen = (event) => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const pulseAnimation = `
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.05);
    }
    100% {
      transform: scale(1);
    }
  }
`;

  const speedDialStyle =
    sx?.position === "fixed" && sx?.bottom === 64 && sx?.right === 64
      ? {
          animation: "pulse 2s infinite ease-in-out",
        }
      : {};

  const actions = [
    {
      icon: <RobotIcon />,
      name: "Chat",
      onclick: () => {
        onSpeedDialClick();
      },
    },
    {
      icon: <QuizIcon />,
      name: "Quiz",
      onclick: () => {
        setQuizModalOpen(true);
      },
    },
    //{ icon: <StyleIcon />, name: "Flashcards" }, *For Future FlashCard Feature*
  ];

  return (
    <>
      <style>{pulseAnimation}</style>
      <SpeedDial
        ariaLabel="New Chat SpeedDial"
        icon={<SpeedDialIcon />}
        onClose={handleClose}
        onClick={onSpeedDialClick}
        open={open}
        direction="up"
        sx={{ ...sx, ...speedDialStyle }}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={action.onclick}
          />
        ))}
      </SpeedDial>
      <StartQuizModal
        open={quizModalOpen}
        onClose={() => {
          setQuizModalOpen(false);
        }}
        onStartQuizClick={onStartQuizClick}
      />
    </>
  );
};
