import React, { useEffect, useState } from "react";

const messages = [
  "Connecting you to an AI tutor...",
  "Looking for the best AI expert...",
  "Your AI assistant is warming up...",
  "Fetching your personalized tutor...",
  "Initializing the AI connection...",
];

export const LoadingIndicator = () => {
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentMessageIndex((prevIndex) => (prevIndex + 1) % messages.length);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div
      style={{
        minWidth: "400px",
        padding: "10px",
        backgroundColor: "#f0f0f0",
        borderRadius: "5px",
        textAlign: "center",
      }}
    >
      <p>{messages[currentMessageIndex]}</p>
    </div>
  );
};
