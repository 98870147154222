import React, { useState } from 'react';
import { Box, Typography, Stepper, Step, StepLabel, Container } from '@mui/material';
import SocialProofToast from './SocialProofToast';
import SignInBG from "../../assets/images/SignInBG.png";
import logoPng from "../../assets/images/logo_transparent.png";
import EmailStep from './EmailStep';
import BasicInfoStep from './BasicInfoStep';
import CreateAccountStep from './CreateAccountStep';
import ConfirmationStep from './ConfirmationStep';
import { useThemeContext } from "../../theme/ThemeContext";

const steps = ['Email', 'Info', 'Account', 'Tailor'];

const Onboarding = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [college, setCollege] = useState('');
  const [department, setDepartment] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmation, setConfirmation] = useState(false);
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSubmit = () => {
    // Handle form submission
    setConfirmation(true);
    handleNext();
  };

  const { theme } = useThemeContext();

  const styles = {
    pageContainer: {
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundImage: `url(${SignInBG})`,
      backgroundPosition: "center",
      backgroundSize: "cover",
    },
  };

  return (
    <div style={styles.pageContainer}>
        <Container sx={{
                width: { xs: "90%", sm: "80%", md: "60%", lg: "40%", xl: "20%" },
                minHeight: "60%",
                p: { xs: 2, sm: 3 },
                backgroundColor: theme.palette.background.paper,
                boxShadow: "0px 8px 10px 10px rgba(0, 0, 0, 0.20)",
                borderRadius: 2,
                overflow: "hidden",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: 3,
            }}
            >
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <img src={logoPng} alt="logo" width={64} height={64} />
            </Box>
            <Typography variant="h4" component="h1" gutterBottom align="center" color="primary" sx={{ fontWeight: 'bold', fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' } }}>
                {process.env.REACT_APP_UNIVERSITY_NAME || ''} Onboarding
                <Typography variant="h6" component="h2" gutterBottom align="center" color="primary" sx={{ fontSize: { xs: '1rem', sm: '1.25rem', md: '1.5rem' } }}>
                    Tailor Your Tutor for Free
                </Typography>
            </Typography>
            <Stepper activeStep={activeStep} alternativeLabel sx={{ width: '100%', }}>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <Box sx={{ width: '100%' }}>
                {activeStep === 0 && (
                    <EmailStep email={email} setEmail={setEmail} handleNext={handleNext}/>
                )}
                {activeStep === 1 && (
                    <BasicInfoStep firstName={firstName} setFirstName={setFirstName} lastName={lastName} 
                    setLastName={setLastName} handleNext={handleNext} college={college} setCollege={setCollege}
                    department={department} setDepartment={setDepartment} handleBack={handleBack}/>
                )}
                {activeStep === 2 && (
                    <CreateAccountStep username={username} setUsername={setUsername} 
                    password={password} setPassword={setPassword} handleNext={handleSubmit} handleBack={handleBack}
                    setConfirmation={setConfirmation} />
                )}
                {activeStep === 3 && (
                    <ConfirmationStep confirmation={confirmation} handleBack={handleBack} />
                )}
            </Box>
        </Container>
        <SocialProofToast />
    </div>
  );
};

export default Onboarding;