import { Box, Button } from "@mui/material";
import React from "react";

export const CreateThreadButton = ({
  setToGhostThread,
  isTyping,
  loadingCreateThread,
}) => {
  return (
    <Box sx={{ padding: 2 }}>
      <Button
        variant="contained"
        color="primary"
        disabled={isTyping || loadingCreateThread}
        fullWidth
        onClick={setToGhostThread}
      >
        New Thread
      </Button>
    </Box>
  );
};
