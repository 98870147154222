import { Box, Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useState } from "react";
import { useMobile } from "../../contexts/MobileContext";
import Cookies from "js-cookie";

export const NewThreadEncourager = () => {
  const isMobile = useMobile().isMobile;
  const [showEncourager, setShowEncourager] = useState(
    !Cookies.get("hideNewThreadEncourager")
  );

  useEffect(() => {
    if (!showEncourager) {
      const expiryDate = new Date(Date.now() + 15 * 60 * 1000);
      Cookies.set("hideNewThreadEncourager", "true", { expires: expiryDate });
    }
  }, [showEncourager]);

  const handleClose = () => setShowEncourager(false);

  if (!showEncourager) return null;

  return (
    <Box
      sx={{
        position: "absolute",
        bottom: isMobile ? "25%" : "18%",
        right: "2%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: 3,
        borderRadius: 2,
        boxShadow: `0 4px 8px rgba(0, 0, 0, 0.1)`,
        backgroundColor: "white",
        zIndex: 1000,
        opacity: 0.9,
        maxWidth: "20%",
      }}
    >
      <IconButton
        sx={{ position: "absolute", top: 0, right: 0 }}
        onClick={handleClose}
      >
        <CloseIcon />
      </IconButton>
      <Typography variant="body1" color="textPrimary" mt={1} mb={2}>
        Long conversations can reduce clarity. Starting a new thread helps keep
        the conversation on track. Click the "+" to do so.
      </Typography>
    </Box>
  );
};

