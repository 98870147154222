import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  FormGroup,
  IconButton,
  InputAdornment,
  Modal,
  Stack,
  TextField,
  Typography,
  darken
} from "@mui/material";
import { React, useState } from "react";
import logoPng from "../../assets/images/logo_transparent.png";
import { useMobile } from "../../contexts/MobileContext";
import { usePasswordLogin } from "../../hooks/usePasswordLogin";
import { useValidateAccessCode } from "../../hooks/useValidateAccessCode";

export const LoginModal = ({
  handleUsernameChange,
  handleAccessCodeSignup,
  handleAccessCodeChange,
  handlePasswordChange,
  handleConfirmPasswordChange,
  confirmPassword,
  onPasswordLoginSuccess,
  courseData,
  setCourseData,
  isLoadingAccessCode,
  theme,
}) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [accessCode, setAccessCode] = useState("");
  const [showSignUp, setShowSignUp] = useState(false);
  const [signUpModalOpen, setSignUpModalOpen] = useState(false);
  const [usernameValidationMessage, setUsernameValidationMessage] =
    useState("");
  const [passwordValidationMessage, setPasswordValidationMessage] =
    useState("");
  const [errors, setErrors] = useState({
    username: "",
    password: "",
    login: "",
  });
  const isMobile = useMobile().isMobile;

  const [forgotPasswordModalOpen, setForgotPasswordModalOpen] = useState(false);
  const [accessCodeError, setAccessCodeError] = useState("");
  const forgotPasswordEmail = "ahir@tailoredtutor.net";

  const handleShowLogin = () => {
    setShowSignUp(false);

    setUsername("");
    setPassword("");
    setErrors({
      username: "",
      password: "",
      login: "",
    });
  };

  const handleSuccessfulAccessCode = () => {
    setSignUpModalOpen(false);
    setUsername("");
    setPassword("");
    setShowSignUp(true);
    setUsernameValidationMessage("");
    setPasswordValidationMessage("");
  };

  const validateAccessCode = useValidateAccessCode(
    setCourseData,
    handleSuccessfulAccessCode
  );

  const handleAccessCodeSubmit = (accessCode) => {
    validateAccessCode.mutate(
      { accessCode },
      {
        onSuccess: (data) => {
          setAccessCodeError("");
          handleCloseSignUp();
        },
        onError: (error) => {
          setAccessCodeError("Invalid Access Code");
        },
      }
    );
  };

  const {
    mutate: passwordLogin,
    isError,
    error,
    isLoading: isPasswordLoading,
  } = usePasswordLogin(onPasswordLoginSuccess);

  const onLogin = (event) => {
    event.preventDefault(); // Prevent default form submission
    let errorState = { username: "", password: "", login: "" };

    if (!username) {
      errorState.username = "Username is required";
    }
    if (!password) {
      errorState.password = "Password is required";
    }

    if (errorState.username || errorState.password) {
      setErrors(errorState);
      return;
    }

    passwordLogin({ username, password });
  };

  if (isError && error instanceof Error) {
    switch (error.type) {
      case "userNotFound":
        errors.username = "User not found. Please register if you're new.";
        errors.password = "";
        errors.login = "";
        break;
      case "invalidCredentials":
        errors.password = "Incorrect username or password. Please try again.";
        errors.username = "";
        errors.login = "";
        break;
      default:
        break;
    }
  }
  const validateUsername = (value) => {
    if (value.length < 6 || value.length > 24 || /[^a-zA-Z0-9]/.test(value)) {
      setUsernameValidationMessage(
        "Username must be 6-24 characters and cannot contain special characters."
      );
      return false;
    } else {
      setUsernameValidationMessage("");
      return true;
    }
  };

  const validatePassword = (passwordValue, confirmPasswordValue) => {
    // Allow letters, numbers, and specific special characters, reject others
    const passwordRegex = /^[a-zA-Z0-9!@#$%^&*()_+]{6,24}$/;

    if (!passwordRegex.test(passwordValue)) {
      setPasswordValidationMessage(
        "Password must be 6-24 characters and can include these special characters: [! @ # $ % ^ & * ( ) _ +]."
      );
      return false;
    } else if (passwordValue !== confirmPasswordValue) {
      setPasswordValidationMessage("Passwords must match.");
      return false;
    } else {
      setPasswordValidationMessage("");
      return true;
    }
  };

  const handleOpenSignUp = () => {
    setSignUpModalOpen(true);
  };
  const handleCloseSignUp = () => {
    setSignUpModalOpen(false);
  };

  const handleOpenForgotPassword = () => {
    setForgotPasswordModalOpen(true);
  };
  const handleCloseForgotPassword = () => {
    setForgotPasswordModalOpen(false);
  };

  const onUsernameChange = async (event) => {
    const value = event.target.value;
    setUsername(value);
    await handleUsernameChange(event);
    validateUsername(value);
  };

  const onPasswordChange = async (event) => {
    const value = event.target.value;
    setPassword(value);
    await handlePasswordChange(event);
    validatePassword(value, confirmPassword);
  };

  const onConfirmPasswordChange = async (event) => {
    const value = event.target.value;
    await handleConfirmPasswordChange(event);
    validatePassword(password, value);
  };

  const onSignup = (event) => {
    event.preventDefault();
    const validUsername = validateUsername(username);
    const validPassword = validatePassword(password, confirmPassword);
    if (validUsername && validPassword) {
      handleAccessCodeSignup(accessCode, username, password);
    }
  };

  const isAnyLoading = isPasswordLoading || isLoadingAccessCode;

  return (
    <Box
      sx={{
        width: "20%",
        minWidth: "200px",
        minHeight: "60%",
        maxHeight: "90%",
        p: 3,
        backgroundColor: theme.palette.background.paper,
        boxShadow: "0px 8px 10px 10px rgba(0, 0, 0, 0.20)",
        borderRadius: 2,
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: 3,
      }}
    >
      <Stack direction="column" justifyContent="center" alignItems="center">
        <img src={logoPng} alt="logo" width={64} height={64} />
        <Typography
          sx={{
            textAlign: "center",
            color: theme.palette.primary.main,
            fontSize: isMobile ? "1.8rem" : "2.5rem",
            fontWeight: 700,
            lineHeight: isMobile ? "2.5rem" : "3.625rem",
            letterSpacing: "0.15px",
          }}
        >
          Welcome to Tailored Tutor
        </Typography>
        {!isMobile && (
          <Typography
            sx={{
              textAlign: "center",
              color: "rgba(0, 0, 0, 0.64)",
              fontSize: "1rem",
              fontWeight: 400,
              lineHeight: "1.5rem",
              letterSpacing: "0.15px",
              marginTop: 1,
              marginBottom: -2,
            }}
          >
            Login or sign up to get started using your <br />
            AI-powered personal tutor.
          </Typography>
        )}
      </Stack>

      {!showSignUp && (
        <>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: 1,
            }}
          >
            <form onSubmit={onLogin}>
              <TextField
                label="Username"
                type="text"
                autoComplete="username"
                fullWidth
                disabled={isAnyLoading}
                variant="outlined"
                margin="normal"
                value={username}
                onChange={onUsernameChange}
                error={!!errors.username}
                helperText={errors.username || errors.login}
              />
              <TextField
                label="Password"
                type="password"
                fullWidth
                disabled={isAnyLoading}
                variant="outlined"
                margin="normal"
                autoComplete="current-password"
                value={password}
                onChange={onPasswordChange}
                error={!!errors.password}
                helperText={errors.password || errors.login}
              />
              <Button
                fullWidth
                onClick={onLogin}
                disabled={isAnyLoading}
                type="submit"
                sx={{
                  color: "white",
                  mt: 1,
                  bgcolor: theme.palette.primary.main,
                  ":hover": { bgcolor: darken(theme.palette.primary.main, 0.3) },
                }}
              >
                Login
              </Button>
            </form>
            <Button
              sx={{
                textAlign: "center",
                color: "#5C6BC0",
                fontSize: isMobile ? "0.875rem" : "1rem",
                fontWeight: 700,
                textTransform: "none",
                padding: 0,
                marginBottom: "-10px",
                minWidth: 0,
                "&:hover": {
                  bgcolor: "transparent",
                  textDecoration: "underline",
                },
              }}
              onClick={handleOpenForgotPassword}
            >
              Forgot Your Password?
            </Button>
            <Typography
              sx={{
                textAlign: "center",
                color: "rgba(0, 0, 0, 0.64)",
                fontSize: isMobile ? "0.875rem" : "1rem",
                marginBottom: "-10px",
              }}
            >
              Don’t have an account?
            </Typography>
            <Button
              sx={{
                textAlign: "center",
                color: "#5C6BC0",
                fontSize: isMobile ? "0.875rem" : "1rem",
                fontWeight: 700,
                textTransform: "none",
                padding: 0,
                minWidth: 0,
                "&:hover": {
                  bgcolor: "transparent",
                  textDecoration: "underline",
                },
              }}
              onClick={handleOpenSignUp}
            >
              Sign up with an access code
            </Button>
          </Box>
        </>
      )}

      {showSignUp && (
        <Box p={isMobile ? 1 : 2}>
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              fontSize: isMobile ? "1.25rem" : "1.5rem",
              mt: isMobile ? -3 : 2,
            }}
          >
            Signup for {courseData["courses"]} as{" "}
            {courseData["isAdmin"] ? "an instructor" : "a student"}
          </Typography>
          <form onSubmit={onSignup}>
            <FormGroup>
              <TextField
                label="Username"
                type="text"
                autoComplete="username"
                fullWidth
                disabled={isAnyLoading}
                variant="outlined"
                margin="normal"
                value={username}
                onChange={onUsernameChange}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    event.preventDefault();
                    onSignup(event);
                  }
                }}
              />
              {usernameValidationMessage && (
                <Typography
                  color="error"
                  sx={{ fontSize: isMobile ? "0.875rem" : "1rem" }}
                >
                  {usernameValidationMessage}
                </Typography>
              )}
              <TextField
                label="Password"
                type="password"
                autoComplete="new-password"
                disabled={isAnyLoading}
                fullWidth
                variant="outlined"
                margin="normal"
                value={password}
                onChange={onPasswordChange}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    event.preventDefault();
                    onSignup(event);
                  }
                }}
              />
              <TextField
                label="Confirm Password"
                type="password"
                autoComplete="new-password"
                disabled={isAnyLoading}
                fullWidth
                variant="outlined"
                margin="normal"
                value={confirmPassword}
                onChange={onConfirmPasswordChange}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    event.preventDefault();
                    onSignup(event);
                  }
                }}
              />
              {passwordValidationMessage && (
                <Typography
                  color="error"
                  sx={{ fontSize: isMobile ? "0.875rem" : "1rem" }}
                >
                  {passwordValidationMessage}
                </Typography>
              )}
              <Button
                variant="contained"
                color="primary"
                sx={{ mt: isMobile ? 1 : 2 }}
                type="submit"
              >
                Signup
              </Button>
            </FormGroup>
          </form>
          <Button
            sx={{
              textAlign: "center",
              color: "#5C6BC0",
              fontSize: isMobile ? "0.875rem" : "1rem",
              fontWeight: 700,
              textTransform: "none",
              padding: 0,
              minWidth: 0,
              mt: isMobile ? 1 : 2,
              "&:hover": {
                bgcolor: "transparent",
                textDecoration: "underline",
              },
            }}
            onClick={handleShowLogin}
          >
            Already have an account? Login here
          </Button>
        </Box>
      )}

      <>
        <Modal
          open={forgotPasswordModalOpen}
          onClose={handleCloseForgotPassword}
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: isMobile ? "70%" : 400,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              outline: "none",
            }}
          >
            <IconButton
              aria-label="close"
              color="primary"
              onClick={handleCloseForgotPassword}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
              }}
            >
              <CloseIcon />
            </IconButton>
            <Box sx={{ mt: 2 }}>
              <Typography variant="body1" sx={{ mb: 2 }}>
                To reset your password, please click the button below to draft
                an email to our support team.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                component="a"
                href="mailto:ahir@tailoredtutor.net?subject=Forgot Password&body=Please help me reset my password."
              >
                Draft Email
              </Button>
            </Box>
          </Box>
        </Modal>

        <Modal
          open={signUpModalOpen}
          onClose={handleCloseSignUp}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: isMobile ? "70%" : 400,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              outline: "none",
            }}
          >
            <IconButton
              aria-label="close"
              color="primary"
              onClick={handleCloseSignUp}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
              }}
            >
              <CloseIcon />
            </IconButton>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Enter your access code
            </Typography>
            <TextField
              error={!!accessCodeError}
              helperText={accessCodeError}
              label="Access Code"
              type="text"
              fullWidth
              variant="outlined"
              margin="normal"
              value={accessCode}
              onChange={(e) => setAccessCode(e.target.value)}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  event.preventDefault();
                  handleAccessCodeSubmit(accessCode);
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      color="primary"
                      edge="end"
                      onClick={() => {
                        handleAccessCodeSubmit(accessCode);
                      }}
                    >
                      <ArrowUpwardIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Modal>
      </>
    </Box>
  );
};
