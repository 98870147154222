import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Button } from '@mui/material';
import { useLocation } from 'react-router-dom';

const EmailStep = ({ email, setEmail, handleNext }) => {

  const [isEmailFromUrl, setIsEmailFromUrl] = useState(false);

  const [emailTimeout, setEmailTimeout] = useState(null);
  const [emailError, setEmailError] = useState(false);

  const validateEmail = (email) => {
    const regex = new RegExp(`@${process.env.REACT_APP_EMAIL_PATTERN}$`);
    setEmailError(!regex.test(email) && email !== '' && email !== null);
  };

  const handleEmailChange = (e) => {
    clearTimeout(emailTimeout);
    const email = e.target.value;
    setEmail(email);
    setEmailTimeout(
      setTimeout(() => {
        validateEmail(email);
      }, 750)
    );
  };

  const handleEmailClick = () => {
    validateEmail(email);
    if (!emailError) {
      handleNext();
    } else {
      setEmailTimeout(setTimeout(() => {
        setEmailError(false);
      }, 2000));
    }
  };

  const location = useLocation();
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const emailParam = params.get('email');
    if (emailParam) {
      setEmail(emailParam);
      setIsEmailFromUrl(true);
    }
  }, [location, setEmail]);

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
          {isEmailFromUrl ? 'Confirm Your Email' : 'Enter Your Email'}
      </Typography>
      <TextField
          label="Email"
          type="email"
          value={email}
          defaultValue={email}
          onChange={handleEmailChange}
          fullWidth
          margin="normal"
          required
          error={emailError}
          helperText={emailError && `Email must match the pattern: @${process.env.REACT_APP_EMAIL_PATTERN}`}
          FormHelperTextProps={{
          sx: {
              color: 'red',
          },
          }}
          onKeyDown={(e) => {
              if (e.key === 'Enter') {
                  handleEmailClick();
              }
          }}
      />
      {isEmailFromUrl && (
          <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
            This email was pre-filled from the URL. Please confirm or edit if necessary.
          </Typography>
      )}
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
          <Button variant="contained" color="primary" onClick={handleEmailClick} disabled={emailError || (email === '' && !isEmailFromUrl)}>
              {isEmailFromUrl ? 'Confirm' : 'Next'}
          </Button>
      </Box>
    </Box>
  );
};

export default EmailStep;