import React, { useState } from 'react';
import { Box, Typography, TextField, Button } from '@mui/material';
const CreateAccountStep = ({ username, setUsername, password, setPassword, setConfirmation, handleNext, handleBack }) => {
  
  const [confirmPassword, setConfirmPassword] = useState('');
  const [usernameError, setUsernameError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);

  const handleCreateAccountSubmit = () => {
      const usernameRegex = /^(?!.*\s)(?!^.*[!"#$%&'()*+,./:;<=>?@[\]^_`{|}~])(?!^.*[0-9_])[a-zA-Z_][a-zA-Z0-9_]{5,19}$/;
      const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]{8,}$/;
      let isValid = true;

      if (!usernameRegex.test(username)) {
          setUsernameError(true);
          isValid = false;
      } else {
          setUsernameError(false);
      }
      if (!passwordRegex.test(password)) {
          setPasswordError(true);
          isValid = false;
      } else {
          setPasswordError(false);
      }
      if (password !== confirmPassword) {
          setConfirmPasswordError(true);
          isValid = false;
      } else {
          setConfirmPasswordError(false);
      }
      if (isValid) {
          setUsernameError(false);
          setPasswordError(false);
          setConfirmPasswordError(false);
          // Create account
          // if successful, set confirmation to true
          // if not, set confirmation to false
          setConfirmation(true);
          handleNext();
      }
  }

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
          Create a Tailored Tutor Account
      </Typography>
      <TextField
          label="Username (Separate from Email)"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          fullWidth
          margin="normal"
          required
          onKeyDown={(e) => {
              if (e.key === 'Enter') {
                  handleCreateAccountSubmit();
              }
          }}
      />
      {usernameError && (
          <Typography variant="body2" color="error">
              Username must be 6-24 characters long, can't have whitespace, and no special characters.
          </Typography>
      )}
      <TextField
          label="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          fullWidth
          margin="normal"
          required
          type="password"
          onKeyDown={(e) => {
              if (e.key === 'Enter') {
                  handleCreateAccountSubmit();
              }
          }}
      />
      {passwordError && (
          <Typography variant="body2" color="error">
              Password must be at least 8 characters long, contain at least one letter and one number, and cannot contain whitespace.
          </Typography>
      )}
      <TextField
          label="Confirm Password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          fullWidth
          margin="normal"
          required
          type="password"
          onKeyDown={(e) => {
              if (e.key === 'Enter') {
                  handleCreateAccountSubmit();
              }
          }}
      />
      {confirmPasswordError && (
          <Typography variant="body2" color="error">
              Passwords do not match.
          </Typography>
      )}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
          <Button onClick={handleBack}>Back</Button>
          <Button variant="contained" color="primary" onClick={handleCreateAccountSubmit}>
          Submit
          </Button>
      </Box>
    </Box>
  );
};

export default CreateAccountStep;