import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';

const SocialProofToast = ( ) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [visible, setVisible] = useState(false);
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    // Fetch notifications from your backend or use dummy data
    // This is where you would populate the notifications as professors sign up
    setNotifications([
      { actionType: 'signup', department: 'Computer Science', timestamp: new Date(Date.now() - 2 * 60 * 60 * 1000).toISOString() },
      { actionType: 'onboarding', timestamp: new Date(Date.now() - 25 * 60 * 60 * 1000).toISOString() },
      // ... more notifications ...
    ]);
  }, []);

    useEffect(() => {
        if (notifications.length === 0) return;

        const showNotification = () => {
            setVisible(true);
            setCurrentIndex((prevIndex) => (prevIndex + 1) % notifications.length);
        };

        const hideNotification = () => {
            setVisible(false);
        };

        let interval;
        const initialDelay = setTimeout(() => {
            showNotification();

            interval = setInterval(() => {
            hideNotification();

            setTimeout(showNotification, 3000);
            }, 9000);
        }, 3000);

        return () => {
            clearTimeout(initialDelay);
            clearInterval(interval); 
        };
    }, [notifications]);


  const formatTimeAgo = (timestamp) => {
    const now = new Date();
    const actionTime = new Date(timestamp);
    const diffHours = Math.max(1, Math.floor((now - actionTime) / (1000 * 60 * 60)));

    if (diffHours < 24) {
      return `${diffHours} hour${diffHours !== 1 ? 's' : ''} ago`;
    } else {
      const diffDays = Math.floor(diffHours / 24);
      return `${diffDays} day${diffDays !== 1 ? 's' : ''} ago`;
    }
  };

  if (notifications.length === 0) return null;

  const currentNotification = notifications[currentIndex];

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 20,
        left: visible ? 20 : -320, // Slide out of view when not visible
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        borderRadius: 2,
        padding: 2,
        boxShadow: 2,
        maxWidth: 300,
        opacity: visible ? 1 : 0,
        transition: 'all 1s ease-in-out',
      }}
    >
      <Typography variant="body2">
        A {process.env.REACT_APP_UNIVERSITY_NAME || ''} professor {currentNotification.department ? `from the ${currentNotification.department} department` : ''} 
        {currentNotification.actionType === 'signup' ? ' signed up' : ' started onboarding'}
      </Typography>
      <Typography variant="caption" color="text.secondary">
        {formatTimeAgo(currentNotification.timestamp)}
      </Typography>
    </Box>
  );
};

export default SocialProofToast;