//move filter to here
//change the app.js fetch on handleStudentChange to hook
//filter by the selected course

export const filterByCourse = (courseId, unfilteredThreads) => {
    return unfilteredThreads
      .filter((thread) => thread.courseId === courseId)
      .sort((a, b) => {
        // Check if both threads have the 'lastMessageSentTimeISO8601' attribute
        if (a.lastMessageSentTimeISO8601 && b.lastMessageSentTimeISO8601) {
          // Both threads have the attribute, sort by the ISO8601 time (latest first)
          return (
            new Date(b.lastMessageSentTimeISO8601) -
            new Date(a.lastMessageSentTimeISO8601)
          );
        } else if (a.lastMessageSentTimeISO8601) {
          // Only 'a' has the attribute, so 'a' should come before 'b'
          return -1;
        } else if (b.lastMessageSentTimeISO8601) {
          // Only 'b' has the attribute, so 'b' should come before 'a'
          return 1;
        } else {
          // Neither thread has the attribute, maintain existing order
          return 0;
        }
      });
  };