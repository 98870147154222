import { useMutation } from "react-query";

/**Grabs the messages within a specific thread
 *
 * @param {threadId}
 *    @threadId identifies the thread that we will retreive the messages from
 * @returns {mutation, response}
 *    @mutation the mutated hook to be used to retrieve messages.
 *    @response the list of messages for the thread as strings
 */

const fetchThreadMessages = async (threadId) => {
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_API}/threads/getThreadMessages/${threadId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      }
    }
  );

  const getThreadMessagesResponse = await response.json();
  if (getThreadMessagesResponse.statusCode === 200) {
    return getThreadMessagesResponse.response;
  } else {
    console.error(response.response);
    throw new Error("Error fetching thread messages");
  }
};

export const useGetThreadMessages = (setMessages) => {
  return useMutation(fetchThreadMessages, {
    onError: (error) => {
      console.error(
        "There was an error fetching the threadMessages data:",
        error
      );
    },
    onSuccess: (data) => {
      setMessages(data);
    },
  });
};
