import { useMutation } from "react-query";
import { useRef } from "react";

/**Deletes a thread from a user by using threadId
 *
 * This hook provides an abstraction for deleting thread data from a specified users data.
 *
 *
 * @param {userId, threadId, courseId}
 *    @userId the user object that has the thread to be deleted
 *    @threadId a string that identifies the thread to be deleted
 *    @courseId a string that identifies the course for which the thread is being deleted
 * @returns {mutation}
 *    @mutation the mutated hook to be used to delete the thread.
 */

const deleteThread = async (userId, threadId, courseId) => {
  const payload = {
    userId: userId,
    threadId: threadId,
    courseId: courseId
  };
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_API}/threads/deleteThread`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }
    );

  if (response.status !== 200) {
    throw new Error(`Server responded with status: ${response.status}`);
  }
  const deleteThreadResponse = await response.json();
  if (deleteThreadResponse.statusCode !== 200) {
    throw new Error(`Failed to delete thread: ${deleteThreadResponse.response}`);
  }
  return deleteThreadResponse.response; // returns the threadResponse
  } catch (error) {
    console.error("There was an error deleting the thread:", error);
  }
  return {};
};

export const useDeleteThread = () => {
  const resolveMutation = useRef(null);

  const mutation = useMutation(([userId, threadId, courseId]) => deleteThread(userId, threadId, courseId), {
    onSettled: (data, error) => {
      if (resolveMutation.current) {
        if (error) {
          resolveMutation.current.reject({ error });
        } else {
          resolveMutation.current.resolve({ data });
        }
      }
    },
  });

  const deleteThreadWithPromise = (userId, threadId, courseId) => {
    return new Promise((resolve, reject) => {
      resolveMutation.current = { resolve, reject };
      mutation.mutate([userId, threadId, courseId]);
    });
  };

  return { ...mutation, deleteThread: deleteThreadWithPromise };
};
