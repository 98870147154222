import { useMutation } from "react-query";
import { useRef } from "react";

/** Renames a thread with a new name.
 *
 * @param {threadId, newName}
 *    @threadId identifies the threadId to rename
 *    @newName is the new name for the thread
 * @returns {mutation, data}
 *    @mutation the mutated hook to be used to rename a thread.
 *    @data returns a boolean if it was successful or not
 */

const renameThread = async (threadId, newName) => {
  const payload = {
      threadId: threadId,
      newName: newName,
    };
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_API}/threads/renameThread`,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    }
  );

  if (response.status === 200) {
    const data = await response.json();
    return data;
  } else {
    throw new Error(`Server responded with status: ${response.status}`);
  }
};
 
export const useRenameThread = () => {
  const resolveMutation = useRef(null);

  const mutation = useMutation(([threadId, newName]) => renameThread(threadId, newName), {
    onSettled: (data, error) => {
      if (resolveMutation.current) {
        if (error) {
          resolveMutation.current.reject({ error });
        } else {
          resolveMutation.current.resolve({ data });
        }
      }
    },
  });

  const renameThreadWithPromise = (threadId, newName) => {
    return new Promise((resolve, reject) => {
      resolveMutation.current = { resolve, reject };
      mutation.mutate([threadId, newName]);
    });
  };

  return { ...mutation, renameThread: renameThreadWithPromise };
};