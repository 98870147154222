import { useMutation } from "react-query";

/**
 * This hook allows a student to sign-up and auto-register for a tutor.
 *
 * @param {String} accessCode the course accessCode, should already be verified from validateAccessCode
 * @param {String} username the username that the user has chosen
 * @param {String} password the password that the user has chosen
 * @returns The User object for the newly created user if successful, an error if unsuccessful.
 */

const accessCodeSignup = async (accessCode, username, password) => {
  const payload = {
    method: "accessCodeSignup",
    accessCode: accessCode,
    username: username,
    password: password,
  };

  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_API}/users/accessCodeSignup`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      }
    );

    const accessCodeResponse = await response.json();

    if (response.status === 200) {
      return accessCodeResponse.response;
    } else {
      throw new Error("A backend server error occurred");
    }
  } catch (error) {
    console.error("Error signing up: ", error);
    throw error;
  }
};

export const useAccessCodeSignup = (onPasswordLoginSuccess) => {
  return useMutation(
    ({ accessCode, username, password }) =>
      accessCodeSignup(accessCode, username, password),
    {
      onSuccess: (data) => {
        onPasswordLoginSuccess(data);
      },
      onError: (error) => {
        alert(error);
      },
    }
  );
};
