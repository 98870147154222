import React, { useState } from 'react';
import { Box, Typography, TextField, Button, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
const BasicInfoStep = ({ firstName, setFirstName, lastName, setLastName, college, 
  setCollege, department, setDepartment, handleNext, handleBack }) => {
    
  const collegesToDepartments = {
    'School of the Arts & Architecture': [
      'Architecture and Urban Design',
      'Art',
      'Design | Media Arts',
      'World Arts and Cultures/Dance'
    ],
    'College of Letters and Science': [
      'Humanities',
      'Life Sciences',
      'Physical Sciences',
      'Social Sciences',
      'Undergraduate Education'
    ],
    'School of Dentistry': [
      'Regenerative and Reconstructive Sciences',
      'Diagnostic and Surgical Sciences',
      'Preventive and Restorative Sciences',
      'Oral and Systemic Health Sciences'
    ],
    'School of Education & Information Studies': [
      'Education',
      'Information Studies'
    ],
    'Samueli School of Engineering': [
      'Bioengineering',
      'Chemical and Biomolecular Engineering',
      'Civil and Environmental Engineering',
      'Computer Science',
      'Electrical and Computer Engineering',
      'Materials Science and Engineering',
      'Mechanical and Aerospace Engineering',
      'Computational Medicine'
    ],
    'School of Law': [
      'Business & Tax Law',
      'Constitutional & Public Law',
      'Criminal Justice',
      'Critical Race Studies',
      'Entertainment Law',
      'Environmental Law',
      'Health Law',
      'Human Rights',
      'Immigration Law',
      'Indian Law',
      'Intellectual Property',
      'International Law',
      'Law & Economics',
      'Law & Philosophy',
      'Law & Sexuality',
      'Public Interest Law',
      'Technology & the Law',
      'Trial Advocacy'
    ],
    'Anderson School of Management': [
      'Accounting Decisions, Operations, and Technology Management',
      'Finance',
      'Global Economics and Management',
      'Management and Organizations',
      'Marketing',
      'Strategy'
    ],
    'Herb Alpert School of Music': [
      'Department of Music',
      'Department of Ethnomusicology',
      'Department of Musicology',
      'Global Jazz Studies',
      'Music Industry'
    ],
    'School of Nursing': [
      'Biobehavioral Sciences',
      'Biologic Sciences',
      'Health Disparities/Vulnerable Populations',
      'Health Services Research',
    ],
    'Luskin School of Public Affairs': [
      'Public Policy',
      'Social Welfare',
      'Urban Planning',
    ],
    'Fielding School of Public Health': [
      'Biostatistics',
      'Community Health Sciences',
      'Environmental Health Sciences',
      'Epidemiology',
      'Health Policy and Management',
      'Molecular Toxicology',
    ],
    'School of Theater, Film, and Television': [
      'Department of Theater',
      'Department of Film, Television, and Digital Media',
    ]
  };

  const [basicInfoError, setBasicInfoError] = useState(false);
  const [basicInfoErrorTimeout, setBasicInfoErrorTimeout] = useState(null);

  const handleBasicInfoNextClick = () => {
    if (department === '' || firstName === '' || lastName === '' || college === '') {
      setBasicInfoError(true);
      clearTimeout(basicInfoErrorTimeout);
      setBasicInfoErrorTimeout(setTimeout(() => {
        setBasicInfoError(false);
      }, 2000));
    } else {
      setBasicInfoError(false);
      handleNext();
    }
  }
  
  return (
    <Box>
      <Typography variant="h6" gutterBottom>Basic Information</Typography>
      <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
          We will use this information to show {process.env.REACT_APP_UNIVERSITY_NAME || 'your'} administration your interest in Tailored Tutor.
      </Typography>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <TextField
              label="First Name"
              value={firstName}
              onChange={(e) => {
                  setFirstName(e.target.value);
                  setBasicInfoError(false);
              }}
              fullWidth
              margin="normal"
              required
              sx={{ mr: 2 }}
              onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                      handleBasicInfoNextClick();
                  }
              }}
          />
          <TextField
              label="Last Name"
              value={lastName}
              onChange={(e) => {
                  setLastName(e.target.value);
                  setBasicInfoError(false);
              }}
              fullWidth
              margin="normal"
              required
              onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                      handleBasicInfoNextClick();
                  }
              }}
          />
      </Box>
      <FormControl fullWidth margin="normal" required>
          <InputLabel id="college-label">College</InputLabel>
          <Select
              labelId="college-label"
              value={college}
              onChange={(e) => {
                  setCollege(e.target.value);
                  setBasicInfoError(false);
              }}
              label="College"
              onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                      handleBasicInfoNextClick();
                  }
              }}
          >
              {Object.keys(collegesToDepartments).map((key) => (
                  <MenuItem key={key} value={key}>
                      {key}
                  </MenuItem>
              ))}
          </Select>
      </FormControl>
      {college && (
          <FormControl fullWidth margin="normal" required>
              <InputLabel id="department-label">Department</InputLabel>
              <Select
                  labelId="department-label"
                  value={department}
                  onChange={(e) => {
                      setDepartment(e.target.value);
                      setBasicInfoError(false);
                  }}
                  label="Department"
                  onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                          handleBasicInfoNextClick();
                      }
                  }}
              >
                  {collegesToDepartments[college].map((department) => (
                      <MenuItem key={department} value={department}>
                          {department}
                      </MenuItem>
                  ))}
              </Select>
          </FormControl>
      )}
      {basicInfoError && (
          <Typography variant="body2" color="error" sx={{ mt: 1 }}>
              Please enter all fields to continue.
          </Typography>
      )}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
          <Button onClick={handleBack}>Back</Button>
          <Button variant="contained" color="primary" onClick={handleBasicInfoNextClick} disabled={basicInfoError !== false}>
              Next
          </Button>
      </Box>
    </Box>
  );
};

export default BasicInfoStep;