import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  IconButton,
  ListItem,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { ConfirmDeleteDialog } from "./confirmDeleteThreadDialog";

export const ThreadListItem = React.memo(
  ({
    thread,
    loadingCreateThread,
    index,
    currentThread,
    onThreadClick,
    setSelectedThread,
    theme,
    isMobile,
    handleRenameThread,
    handleDeleteThread,
    isTyping, // Add the isTyping prop here
  }) => {
    const [editingThreadId, setEditingThreadId] = useState(null);
    const [editedName, setEditedName] = useState("");
    const editFieldRefs = useRef({});
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    useEffect(() => {
      if (editingThreadId && editFieldRefs.current[editingThreadId]) {
        const input = editFieldRefs.current[editingThreadId];
        input.focus();
        input.select();
      }
    }, [editingThreadId]);

    const handleDeleteSubmit = () => {
      handleDeleteThread(thread);
      setIsDialogOpen(false);
    };

    const startEditing = (event) => {
      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }
      handleMenuClose();
      setEditingThreadId(thread.threadId);
      setEditedName(thread.threadName);
    };

    const handleRename = () => {
      handleRenameThread(thread, editedName);
      setEditingThreadId(null);
    };

    const [menuAnchorEl, setMenuAnchorEl] = useState(null);

    const handleMenuOpenInternal = (event) => {
      setMenuAnchorEl(event.currentTarget);
      setSelectedThread(thread);
    };

    const handleMenuClose = () => {
      setMenuAnchorEl(null);
    };

    const promptDelete = () => {
      setIsDialogOpen(true);
      handleMenuClose();
    };

    const handleCloseDialog = () => {
      setIsDialogOpen(false);
    };

    return (
      <>
        <ListItem>
          <ListItemButton
            onClick={(event) => {
              if (!isTyping) {
                onThreadClick(thread, false);
                setSelectedThread(thread);
                event.stopPropagation();
              }
            }}
            sx={{
              backgroundColor:
                index % 2 === 0
                  ? theme.palette.action.hover
                  : theme.palette.background.paper,
              "&:hover": {
                backgroundColor: theme.palette.action.selected,
                "& .delete-button": {
                  visibility: "visible",
                },
              },
              "&.Mui-selected, &.Mui-selected:hover": {
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
              },
              justifyContent: "space-between",
            }}
            selected={currentThread?.threadId === thread.threadId}
            disabled={isTyping || loadingCreateThread} // Disable the entire list item when isTyping is true
          >
            {editingThreadId === thread.threadId ? (
              <TextField
                value={editedName}
                onChange={(e) => setEditedName(e.target.value)}
                fullWidth
                onBlur={handleRename}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleRename();
                  }
                }}
                inputRef={(el) => (editFieldRefs.current[thread.threadId] = el)}
              />
            ) : (
              <ListItemText
                sx={{
                  ".MuiTypography-root": {
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    fontSize: isMobile ? "10px" : "20px",
                    maxWidth: "90%",
                  },
                }}
                primary={thread.threadName}
              />
            )}

            {!editingThreadId && (
              <IconButton
                className="options-button"
                onClick={handleMenuOpenInternal}
                edge="end"
                aria-label="options"
                size="small"
                sx={{
                  position: "absolute",
                  right: 8,
                }}
                disabled={isTyping || loadingCreateThread}
              >
                <MoreVertIcon />
              </IconButton>
            )}

            <Menu
              anchorEl={menuAnchorEl}
              open={Boolean(menuAnchorEl)}
              onClose={handleMenuClose}
              disabled={isTyping || loadingCreateThread}
            >
              <MenuItem onClick={startEditing} disabled={isTyping}>
                Rename
              </MenuItem>
              <MenuItem onClick={promptDelete} disabled={isTyping}>
                Delete
              </MenuItem>
            </Menu>
          </ListItemButton>
        </ListItem>
        <ConfirmDeleteDialog
          open={isDialogOpen}
          onClose={handleCloseDialog}
          onConfirm={handleDeleteSubmit}
          title="Confirm Deletion"
          message={`Are you sure you want to delete the thread "${thread?.threadName}"?`}
        />
      </>
    );
  }
);
