import { useMutation } from "react-query";

/**
 * Calls the backend API with a username + password combo.
 *
 * @param {String} username is the username the user is trying to log-in with
 * @param {String} password is the password the user is trying to log-in with
 * @returns the User object if successful, an error if unsuccessful.
 */

const passwordLogin = async (username, password) => {
  const payload = {
    method: "passwordLogin",
    username: username,
    password: password,
  };

  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_API}/users/passwordLogin`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      }
    );

    const passwordLoginResponse = await response.json();

    if (passwordLoginResponse.statusCode === 200) {
      return passwordLoginResponse;
    } else {
      let errorType;

      switch (passwordLoginResponse.statusCode) {
        case 400:
          errorType = "badRequest";

          break;
        case 401:
          errorType = "invalidCredentials";

          break;
        case 403:
          errorType = "forbidden";

          break;
        case 404:
          errorType = "userNotFound";

          break;
        case 500:
          errorType = "serverError";

          break;
        default:
          errorType = "generalError"; // Default error type for other unhandled status codes
          break;
      }

      const error = new Error();
      error.statusCode = passwordLoginResponse.statusCode;
      error.type = errorType;
      throw error;
    }
  } catch (error) {
    console.error("Error during login:", error);
    throw error;
  }
};

export const usePasswordLogin = (onPasswordLoginSuccess) => {
  const mutation = useMutation(
    ({ username, password }) => passwordLogin(username, password),
    {
      onSuccess: (data) => {
        onPasswordLoginSuccess(data.response);
      },
      onError: (error) => {},
    }
  );
  return mutation;
};
